import axios from 'axios';
import { getBaseUrl } from '../services/variables';

axios.defaults.baseURL = getBaseUrl();
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

axios.interceptors.request.use(
  (c) => {
    const config = c;
    const token = localStorage.getItem('smrs_t');
    if (token) config.headers['gosky-api-key'] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.resolve();
    }
    return Promise.reject(error);
  },
);
