import { get } from 'lodash-es';

const variables = {
  endPoint: process.env.REACT_APP_END_POINT,
};

export const getBaseUrl = () => {
  return get(variables, 'endPoint', '');
};

export const getGqlEndPoint = () => {
  return `${get(variables, 'endPoint', '')}/api/console/v3/graphql`;
};

export const getUploadPath = () => {
  return `${get(variables, 'endPoint', '')}/api/console/v3/direct_uploads`;
};
