import { gql } from '@apollo/client';
import { disableFragmentWarnings } from 'graphql-tag';

disableFragmentWarnings();

export const ProjectNameFragment = gql`
  fragment ProjectNameFragment on Project {
    id
    name
  }
`;

export const ProjectBasicInfoFragment = gql`
  fragment ProjectBasicInfoFragment on Project {
    id
    name
    desc: description
    updatedAt
    slug
  }
`;

export const CustomSettingFragment = gql`
  fragment CustomSettingFragment on Project {
    id
    name
    logoImageUrl
    memberCardImageUrl
    webTitle
    themeColor
    preferences {
      fillBirthday
      fillEmail
      fillName
      fillTel
      needOtp
    }
    policy
    contributionButtonLable
    contributionButtonUrl
    awardButtonLable
    awardButtonUrl
    webLang
    chatfuelSignupUrl
    chatfuelSignupSuccessUrl
    chatfuelAccountLinkBlockUrl
    chatfuelApiToken
    lineClientId
    lineClientSecret
    smsQuotaNum
    workingMode
    channelJson {
      messenger
      tel
      line
    }
  }
`;

export const ProjectLevelsFragment = gql`
  fragment ProjectLevelsFragment on Project {
    id
    levels {
      title
      score
    }
  }
`;

export const ProjectApiFragment = gql`
  fragment ProjectApiFragment on Project {
    id
    credentials {
      id
      token
      createdAt
    }
    memberLoginUrl
    accountLinkFlowUrl
    lineRedirectUrl
  }
`;

export const ProjectStoreTagsFragment = gql`
  fragment ProjectStoreTagsFragment on Project {
    storeTags {
      id
      name
    }
  }
`;

export const ProjectUserTagsFragment = gql`
  fragment ProjectUserTagsFragment on Project {
    userTags {
      id
      name
    }
  }
`;

export const ProjectProductTagsFragment = gql`
  fragment ProjectProductTagsFragment on Project {
    productTags {
      id
      name
    }
  }
`;

export const ProjectTagsFragment = gql`
  fragment ProjectTagsFragment on Project {
    id
    ...ProjectStoreTagsFragment
    ...ProjectUserTagsFragment
    ...ProjectProductTagsFragment
  }
  ${ProjectStoreTagsFragment}
  ${ProjectUserTagsFragment}
  ${ProjectProductTagsFragment}
`;

export const PriceToPointFragment = gql`
  fragment PriceToPointFragment on PriceToPointSetting {
    rate
    ruleType
    tags {
      id
      name
      expiredAt
    }
    desc
  }
`;

export const TagSimpleFragment = gql`
  fragment TagSimpleFragment on Tag {
    id
    name
  }
`;
