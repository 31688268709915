import * as types from './types';

const reducer = (state, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_CURRENT_ROUTE:
      return {
        ...state,
        currentRoute: payload,
      };
    case types.SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: payload,
      };
    case types.SET_MATCH_ROUTES:
      return {
        ...state,
        matchRoutes: payload,
      };
    default:
      return state;
  }
};

export default reducer;
