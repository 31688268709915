import React, { useState, useEffect } from 'react';
import './index.scss';
import classNames from 'classnames';
import Loading from '../Loading';

function LoadingFullScreen({ active = false }) {
  const [getActive, setActive] = useState(active);
  useEffect(() => setActive(active), [active]);
  const loadingStyle = {
    height: window.innerHeight,
  };
  const loadingClass = classNames('loading-full-screen', {
    'fade-in': getActive,
    'fade-out': !getActive,
  });

  return (
    <div className={loadingClass} style={loadingStyle}>
      <Loading />
    </div>
  );
}

export default LoadingFullScreen;
