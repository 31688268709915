const Restrict = {
  ONLINE: {
    'store-list': true,
    'index-banners': {
      'banner-setting': true,
    },
    'product-setting': true,
    'coupon-disaptch': true,
    'redeem-points': {
      offline: true,
    },
    'price-to-point': true,
  },
  OFFLINE: {},
};

export default Restrict;
