import { useReducer } from 'react';
import initState from './initState';
import reducer from './reducer';

const useRoute = () => {
  const [routeInfo, dispatch] = useReducer(reducer, initState);
  return [routeInfo, dispatch];
};

export default useRoute;
