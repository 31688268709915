import PropTypes from 'prop-types';
import React from 'react';

const ModalFormContent = ({ children, className, style }) => (
  <div className={`mt-0.5 pb-5 px-6 ${className}`} style={style}>
    {children}
  </div>
);

export default ModalFormContent;

ModalFormContent.defaultProps = {
  className: '',
};

ModalFormContent.propTypes = {
  className: PropTypes.string,
};
