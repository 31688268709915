export const ROOT = '/';
export const LOGIN = '/login';

export const PORTAL = '/portal';
export const CURRENT_PROJECT = '/project/:projectId';

// 數據報告
export const DATA_REPORT = `${CURRENT_PROJECT}/data-report`;

// 會員管理
export const USER_MANAGE = `${CURRENT_PROJECT}/user-manage`;

// 會員等級與點數 -> 任務集點
export const TASK_GROUPS = `${CURRENT_PROJECT}/task-groups`;
export const TASK_USER_RECORDS = `${CURRENT_PROJECT}/task-group/:taskGroupId/task/:taskId/users`;
export const TASK_GROUP_USER_RECORDS = `${CURRENT_PROJECT}/task-group/:taskGroupId/users`;
export const TASK_RECORDS = `${CURRENT_PROJECT}/task-group/:taskGroupId/task/:taskId/times`;
export const TASK_GROUP_RECORDS = `${CURRENT_PROJECT}/task-group/:taskGroupId/times`;

// 會員等級與點數 -> 消費集點
export const PRICE_TO_POINT = `${CURRENT_PROJECT}/price-to-point`;

// 會員等級與點數 -> 兌換點數
export const REDEEM_POINTS = `${CURRENT_PROJECT}/redeem-points/:tab?`;
// 會員等級與點數 -> 兌換點數（線上社群）
export const AWARDS_USERS_RECORDS = `${CURRENT_PROJECT}/award/:awardId/users`;
export const AWARDS_RECORDS = `${CURRENT_PROJECT}/award/:awardId/times`;

// 會員等級與點數 -> 等級權益
export const LEVELE_QUITY = `${CURRENT_PROJECT}/level-equity/:tab?`;

// 會員等級與點數 -> 權益發送紀錄
export const GIFTING_RECORDS = `${CURRENT_PROJECT}/gifting-records`;

// 優惠券發送
export const COUPON_DISPATCH_SETTING = `${CURRENT_PROJECT}/coupon-disaptch/:dispatchType?`;

// 實體商店
export const STORE_LIST = `${CURRENT_PROJECT}/store-list`;
export const DEAL_RECORDS = `${CURRENT_PROJECT}/deal-records/:recordsType`;
export const COUPON_LIST = `${CURRENT_PROJECT}/coupons`;

// 設定
export const BASIC_SETTING = `${CURRENT_PROJECT}/basic-setting`;
export const API_SETTING = `${CURRENT_PROJECT}/api-setting`;
export const INDEX_BANNERS = `${CURRENT_PROJECT}/index-banners`;

// 設定標籤
export const PRODUCT_SETTING = `${CURRENT_PROJECT}/product-setting`;
export const TAG_MANAGEMENT = `${CURRENT_PROJECT}/tag-management`;
