import { getBaseUrl } from '../services/variables';

export const PROJECT_TITLE_WORD_LIMIT = 30;
export const PROJECT_DESCRIPTION_WORD_LIMIT = 100;

export const LOGO_MAX_SIZE = 1; // MB
export const LOGO_RESIZE_HEIGHT = 200; // px
export const BANNER_MAX_SIZE = 1; // MB

export const LEVEL_QUANTITY_LIMIT = 6;
export const LEVEL_TITLE_LIMIT = 15;
export const ExpiredModeTypeMap = {
  NEVER: 'NEVER',
  MONTH_AFTER_SIGNUP: 'MONTH_AFTER_SIGNUP',
  MONTH_AFTER_YEAR: 'MONTH_AFTER_YEAR',
};
export const LEVEL_CONDITION_VALUE_LIMIT = 10e6;
export const GIFTS_QUANTITY_LIMIT = 10;
export const GiftCategoriesMap = {
  NEW_MEMBER: 'NEW_MEMBER',
  FIRST_TIME_QUALIFIED: 'FIRST_TIME_QUALIFIED',
  CURRENT_LEVEL: 'CURRENT_LEVEL',
  BIRTHDAY: 'BIRTHDAY',
};
export const GiftTypesMap = {
  POINT: 'POINT',
  COUPON: 'COUPON',
};
export const GiftSendingTypes = {
  MONTHLY: 'MONTHLY',
  ONETIME: 'ONETIME',
  WEEKLY: 'WEEKLY',
  MONTHLY_OR_LAST_DAY: 'MONTHLY_OR_LAST_DAY',
};
export const EXTEND_CONDITION_VALUE_LIMIT = 10e6;
export const ExtendConditionType = {
  POINT: 'POINT',
};

export const MISSION_TYPE_IMAGE_MAXSIZE = 1;
export const MISSION_TYPE_IMAGE_WIDTH = 720;
export const MISSION_TYPE_IMAGE_HEIGHT = 192;
export const MISSION_TYPE_NAME_LIMIT = 30;
export const MISSION_TYPE_IMAGE_RATIOS = [3.5, 4];

export const MISSION_NAME_LIMIT = 30;

export const AWARD_IMAGE_MAXSIZE = 1;
export const AWARD_IMAGE_WIDTH = 300;
export const AWARD_IMAGE_HEIGHT = 480;
export const AWARD_NAME_LIMIT = 30;
export const AWARD_IMAGE_RATIOS = [0.6, 0.65];

export const COUPON_IMAGE_MAXSIZE = 1;
export const COUPON_IMAGE_WIDTH = 300;
export const COUPON_IMAGE_HEIGHT = 480;
export const COUPON_IMAGE_RATIO = [0.6, 0.65];

export const TABLE_ITEMS_PER_PAGE = 10;

export const USER_LEVEL_ADMIN = 'admin';
export const USER_LEVEL_GOSKY_ADMIN = 'gosky_admin';

export const MISSION_POINT_RECORD_COMMENT_LIMIT = 100;

export const USER_MANAGE_ITEMS_PER_PAGE = 10;

export const TAG_TYPE_STORE = 'STORE';
export const TAG_TYPE_USER = 'USER';
export const TAG_TYPE_PRODUCT = 'PRODUCT';
export const TAG_NAME_LIMIT = 30;
export const MAXIMUM_TAG_ON_ONE_OBJECT = 200;

export const STORE_NAME_LIMIT = 15;

export const PRODUCT_NAME_LIMIT = 10;

export const RECORDS_TYPE_POS = 'pos';
export const RECORDS_TYPE_TASK = 'task';
export const RECORDS_TYPE_AWARD = 'award';
export const RECORDS_TYPE_COUPON = 'coupon';

export const BANNER_SETTING = {
  size: 1,
  height: 810,
  width: 1080,
  ratios: [1.15, 1.45],
};
export const PRICE_TO_POINT_DESC_WORD_LIMIT = 300;

export const COUPON_DETAILS_WORD_LIMIT = 300;
export const COUPON_NAME_LIMIT = 30;
export const COUPON_DESC_WORD_LIMIT = 30;
export const COUPON_DISPATCH_DESC_WORD_LIMIT = 100;

export const MAX_INTEGER = 21474835;

export const POINT_VERSION_COUNT = 12;
export const RECENT_ACTIVE_MAX_MONTHS = 36;

export const CONTRIBUTION_API_ENDPOINT = `${getBaseUrl()}/api/chatbot/v1/user/contribution_annal`;
export const AWARD_API_ENDPOINT = `${getBaseUrl()}/api/chatbot/v1/user/award_annal`;

// This is a temporary number represent unlimited dispatch time given by Can
export const A_HUGE_NUMBER_REPRESENT_UNLIMITED = 99999;

export const SAMPLE_EXCEL_PRODUCT =
  'https://storage.googleapis.com/gosky-loyal-backstage.appspot.com/Samples/product_sample.xlsx';
export const SAMPLE_EXCEL_STORE =
  'https://storage.googleapis.com/gosky-loyal-backstage.appspot.com/Samples/store_sample.xlsx';

// OTP related
export const WIRED_NOTIFICATION =
  'https://docs.google.com/forms/d/1JfpXT0tWmbPnTCsWD9q7P4865ou6KX4VLrwHqa7ZuMo/viewform?gxids=7628&edit_requested=true#responses';

export const SMS_LOW_QUOTA_NUM = 500;
export const SMS_BALANCE_CHECK_SESSION_PREFIX = 'smsBalanceChecked_';

export const OTHER_EQUITY_CONTENT_LIMIT = 800;
export const POLICY_CONTENT_LIMIT = 100;

export const CRUD = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

export const WORKING_MODE = {
  OFFLINE: 'OFFLINE',
  ONLINE: 'ONLINE',
};

export const MemberCardDefaults = {
  textColor: '#ffffff',
  bgColor: '#000000',
};

export const EXPIRED_MODE_VALUE_RANGE = [0, 12];

export const LOYAL_FRONTSTAGE_PROJECT_ROOT = 'loyal.goskyai.com/project/';

export const PROJECT_SLUG_MAX_LEN = 30;

export const POS_VERSION = {
  V1: 'v1',
  V2: 'v2',
};
