import { useQuery } from '@apollo/client';
import { get, has, isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { logout } from '../../api/auth';
import defaultPortrait from '../../assets/images/banner/defaultPortrait.svg';
import logoGoSkyLoyal from '../../assets/images/logo/GoSky-Loyal.svg';
import {
  SMS_BALANCE_CHECK_SESSION_PREFIX,
  SMS_LOW_QUOTA_NUM,
} from '../../constants/smrsSetting';
import AuthContext from '../../context/Auth/AuthContext';
import { LOGIN } from '../../routes/routePaths';
import { GetProjectBasicQuery } from '../../services/graphql/queries/project';
import OTPModal from '../../templates/CustomSetting/OTPModal';
import { prefix, suffix } from '../../utils/breadcrumb';
import findCurrentRoute from '../../utils/route/findCurrentRoute';
import ActionList from '../ActionList';
import ConfirmModal from '../ConfirmModal';
import './index.css';

function Navbar({ displayLogo = true }) {
  const params = useParams();
  const smsBalanceChecked = sessionStorage.getItem(
    `${SMS_BALANCE_CHECK_SESSION_PREFIX}${params.projectId}`,
  );
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [otpWarning, setOtpWarning] = useState(false);
  const [otpTutorialModal, setOtpTutorialModal] = useState(false);
  const { name } = findCurrentRoute(location);
  const [smsModalConfig, setSmsModalConfig] = useState({
    color: 'green',
    title: t('otpModal.lowSmsQuota'),
    content: t('otpModal.lowSmsQuotaDesc'),
  });
  const { data } = useQuery(GetProjectBasicQuery, {
    variables: { projectId: params.projectId },
    skip: !params.projectId,
  });
  const needOtp = get(data, 'project.preferences.needOtp');
  const smsQuotaNum = get(data, 'project.smsQuotaNum');
  const actions = useMemo(
    () => [
      {
        text: t('login.logout'),
        onMouseDown: async () => {
          const [loginResp] = await logout();
          if (loginResp) {
            localStorage.removeItem('smrs_t');
            history.push(LOGIN);
          }
        },
      },
      ...(needOtp
        ? [
            {
              text: t('otpModal.smsDepositTutorial'),
              onMouseDown: () => setOtpTutorialModal(true),
            },
          ]
        : []),
    ],
    [needOtp],
  );

  useEffect(() => {
    // nest if statement for readibility reason
    // don't know if it make sense
    if (data && !smsBalanceChecked) {
      if (needOtp && smsQuotaNum === 0) {
        setSmsModalConfig({
          color: 'red',
          title: t('otpModal.emptySmsQuota'),
          content: t('otpModal.emptySmsQuotaDesc'),
        });
        setOtpWarning(true);
      } else if (needOtp && smsQuotaNum <= SMS_LOW_QUOTA_NUM) {
        setOtpWarning(true);
      }
      sessionStorage.setItem(
        `${SMS_BALANCE_CHECK_SESSION_PREFIX}${params.projectId}`,
        true,
      );
    }
  }, [data]);

  const handleCancelWarning = () => {
    setOtpWarning(false);
  };

  const handleConfirmWarning = () => {
    setOtpWarning(false);
    setOtpTutorialModal(true);
  };

  return (
    <>
      <div className="navbar">
        {displayLogo && (
          <div className="logo-without-sidebar">
            <img className="w-28" src={logoGoSkyLoyal} alt="GoSky" />
          </div>
        )}
        <div className="flex-1 px-6 text-18 leading-6 font-semibold text-dark-grey">
          {`${prefix(name)} `}
          {t(`breadcrumb.${name}`)}
          {` ${suffix(name)}`}
        </div>
        <ActionList className="mx-6" actions={actions}>
          <div className="btn-navbar-user">
            <img
              className="h-8 w-8 object-cover rounded-full"
              src={
                !isEmpty(user.portraitUrl) ? user.portraitUrl : defaultPortrait
              }
              alt="Portrait"
            />
            <div className="flex flex-col items-start ml-3 text-dark-grey-300">
              <div className="flex items-center text-14 leading-4 font-semibold">
                {get(user, 'name', '')}
                <i className="gsif gsif-triangle text-blue ml-1 transform rotate-180 scale-50"></i>
              </div>
              <div className="text-12 leading-3 mt-1">
                {has(user, 'level') && t(`adminType.${user.level}`)}
              </div>
            </div>
          </div>
        </ActionList>
      </div>
      <ConfirmModal
        isOpen={otpWarning}
        confirmBtnColor={smsModalConfig.color}
        confirmBtnText={t('otpModal.depositBtn')}
        content={smsModalConfig.content}
        title={smsModalConfig.title}
        // click cancel will trigger click event on actionList
        onCancel={handleCancelWarning}
        // click confirm will open otp tutorial modal
        onConfirm={handleConfirmWarning}
      />
      <OTPModal
        open={otpTutorialModal}
        smsQuotaNum={get(data, 'project.smsQuotaNum', 0)}
        close={() => setOtpTutorialModal(false)}
      />
    </>
  );
}

export default Navbar;

Navbar.propTypes = {
  displayLogo: PropTypes.bool,
};
