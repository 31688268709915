import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

const Accordion = ({
  open = true,
  title,
  children,
  className,
  titleClass,
  contentClass,
  chevronClass,
}) => {
  const [isOpen, setOpen] = useState(true);
  const childrenWrapper = useRef(null);
  const initialHeight = useMemo(() => {
    return childrenWrapper.current?.scrollHeight;
  }, [childrenWrapper.current]);
  const [childrenOffsetHeight, setChildrenOffsetHeight] = useState(
    initialHeight,
  );

  const handleTransitionStart = () => {
    if (childrenWrapper.current) {
      setChildrenOffsetHeight(childrenWrapper.current.scrollHeight);
    }
  };

  useLayoutEffect(() => {
    if (childrenWrapper.current) {
      setOpen(open);
    }
  }, [childrenWrapper.current]);

  useEffect(() => {
    if (childrenWrapper.current) {
      setChildrenOffsetHeight(childrenWrapper.current.scrollHeight);
    }
  }, [children]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    childrenWrapper.current?.addEventListener(
      'transitionstart',
      handleTransitionStart,
    );
    return () => {
      childrenWrapper.current?.removeEventListener(
        'transitionstart',
        handleTransitionStart,
      );
    };
  }, []);

  return (
    <div className={`accordion ${className}`}>
      <div
        className={
          isOpen
            ? `accordion-title active ${titleClass}`
            : `accordion-title ${titleClass}`
        }
        onClick={() => setOpen(!isOpen)}
      >
        {title}
        <i
          className={
            isOpen
              ? `accordion-chevron gsif gsif-chevron-down ${chevronClass}`
              : `accordion-chevron gsif gsif-chevron-right ${chevronClass}`
          }
        />
      </div>

      <div
        className={
          isOpen
            ? `accordion-content active ${contentClass}`
            : `accordion-content ${contentClass}`
        }
        style={{
          maxHeight: isOpen ? `${childrenOffsetHeight}px` : 0,
        }}
        ref={childrenWrapper}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
