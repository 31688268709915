// Reference: https://dev.to/michalczaplinski/super-easy-react-mount-unmount-animations-with-hooks-4foj

import React, { useEffect, useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const Fade = ({ show = false, children }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    <>
      {shouldRender && (
        <div
          className={show ? 'fade-in' : 'fade-out'}
          onAnimationEnd={onAnimationEnd}
        >
          {children}
        </div>
      )}
    </>
  );
};

Fade.propTypes = {
  show: PropTypes.bool,
};

export default Fade;
