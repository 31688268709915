import { useQuery } from '@apollo/client';
import LoadingFullScreen from '@components/LoadingFullScreen';
import Restrict from '@config/restrict';
import AuthContext from '@context/Auth/AuthContext';
import { GetProjectBasicQuery } from '@graphql/queries/project';
import { get, isEmpty } from 'lodash-es';
import React, { useContext } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { LOGIN, PORTAL } from './routePaths';

const PrivateRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  const projectRoutePrefix = /\/project\/\d+\/(.+)/;
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const { loading, data } = useQuery(GetProjectBasicQuery, {
    variables: { projectId },
    skip: !projectId,
  });

  if (isEmpty(user.level)) {
    return <Redirect to={LOGIN} />;
  }

  if (pathname === PORTAL) {
    return children;
  }

  if (loading) {
    return <LoadingFullScreen />;
  }

  if (data && data.project) {
    const path = projectRoutePrefix.exec(pathname)[1].replaceAll('/', '.');

    const { workingMode } = data.project;
    const forbidden = get(Restrict, `${workingMode}.${path}`) === true;

    if (forbidden) {
      return <Redirect to={PORTAL} />;
    }
  }

  return children;
};

export default PrivateRoute;
