import React from 'react';
import RouteContext from './RouteContext';
import useRoute from './useRoute';

const RouteProvider = ({ children }) => {
  const [routeInfo, dispatch] = useRoute();
  return (
    <RouteContext.Provider value={{ routeInfo, dispatch }}>
      {children}
    </RouteContext.Provider>
  );
};

export default RouteProvider;
