import './index.css';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import Portal from '../../containers/Portal';
import Fade from '../Fade';

const Modal = ({
  id = 'modal',
  children,
  persistent = false,
  isOpen = false,
  style = {},
  onRequestClose = () => {},
}) => {
  const [isPulse, setIsPulse] = useState(false);

  const onCloseModal = useCallback(() => {
    if (persistent) {
      setIsPulse(true);
    } else {
      onRequestClose();
    }
  }, [persistent, setIsPulse, onRequestClose]);

  const modalContentClass = classNames('modal-content', {
    'modal-content-pulse': isPulse,
  });

  const modalCenterStyle = style.width ? { width: style.width } : {};

  const onPulseAnimationEnd = () => {
    setIsPulse(false);
  };

  useEffect(() => {
    const handleEscKeyDown = (e) => {
      // keyCode === 27 is Escape.
      if (e.keyCode === 27) onCloseModal();
    };
    if (isOpen) document.addEventListener('keydown', handleEscKeyDown);
    return () => {
      document.removeEventListener('keydown', handleEscKeyDown);
    };
  }, [isOpen, onCloseModal]);

  return (
    <Portal id={id}>
      <Fade show={isOpen}>
        <div className="modal scrollbar" onClick={onCloseModal}>
          <div className="modal-center" style={modalCenterStyle}>
            <div
              className={modalContentClass}
              style={style}
              onClick={(e) => e.stopPropagation()}
              onAnimationEnd={onPulseAnimationEnd}
            >
              {children}
            </div>
          </div>
        </div>
      </Fade>
    </Portal>
  );
};

export default Modal;

Modal.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  persistent: PropTypes.bool,
  isOpen: PropTypes.bool,
  style: PropTypes.object,
  onRequestClose: PropTypes.func,
};
