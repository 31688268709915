export const ROOT = 'root';
export const LOGIN = 'login';
export const PROJECT_OVERVIEW = 'projectOverview';
export const DATA_REPORT = 'dataReport';
export const USER_MANAGE = 'userManage';
export const TASK_GROUPS = 'taskGroups';
export const TASK_USER_RECORDS = 'taskUserRecords';
export const TASK_GROUP_USER_RECORDS = 'taskGroupUserRecords';
export const TASK_RECORDS = 'taskRecords';
export const TASK_GROUP_RECORDS = 'taskGroupRecords';
export const PRICE_TO_POINT = 'priceToPoint';
export const REDEEM_POINTS = 'redeemPoints';
export const AWARDS_USERS_RECORDS = 'awardsUsersRecords';
export const AWARDS_RECORDS = 'awardsRecords';
export const LEVELE_QUITY = 'levelEquity';
export const COUPON_DISPATCH_SETTING = 'couponDispatch';
export const STORE_LIST = 'storeList';
export const DEAL_RECORDS = 'dealRecords';
export const PROJECT_BASIC_SETTING = 'projectBasicSetting';
export const PROJECT_API_SETTING = 'projectApiSetting';
export const PROJECT_COUPON_LIST = 'coupons';
export const PRODUCT_SETTING = 'productSetting';
export const PROJECT_TAG_MANAGEMENT = 'projectTagManagement';
export const PROJECT_INDEX_BANNERS = 'projectIndexBanners';
export const GIFTING_RECORDS = 'giftingRecords';
