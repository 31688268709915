import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import RouteProvider from './context/Route/RouteProvider';
import RouteGuard from './routes/RouteGuard';

// eslint-disable-next-line
console.info(`${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_ENV})`);

function App() {
  return (
    <RouteProvider>
      <Router>
        <RouteGuard />
      </Router>
    </RouteProvider>
  );
}

export default App;
