import { lazy } from 'react';

import * as names from './routeNames';
import * as paths from './routePaths';

const Login = lazy(() => import('@pages/Login'));
const ProjectOverview = lazy(() => import('@pages/ProjectOverview'));
const DataReport = lazy(() => import('@pages/DataReport'));
const UserManage = lazy(() => import('@pages/UserManage'));
const TaskGroups = lazy(() => import('@pages/TaskGroups'));
const TaskUserRecords = lazy(() => import('@pages/TaskUserRecords'));
const TaskGroupUserRecords = lazy(() => import('@pages/TaskGroupUserRecords'));
const TaskRecords = lazy(() => import('@pages/TaskRecords'));
const TaskGroupRecords = lazy(() => import('@pages/TaskGroupRecords'));
const PriceToPoint = lazy(() => import('@pages/PriceToPoint'));
const RedeemPoints = lazy(() => import('@pages/RedeemPoints'));
const AwardUserRecords = lazy(() => import('@pages/AwardUserRecords'));
const AwardRecords = lazy(() => import('@pages/AwardRecords'));
const LevelEquity = lazy(() => import('@pages/LevelEquity'));
const CouponDispatchPage = lazy(() => import('@pages/CouponDispatch'));
const StoreList = lazy(() => import('@pages/StoreList'));
const DealRecords = lazy(() => import('@pages/DealRecords'));
const CouponPage = lazy(() => import('@pages/CouponSetting'));
const ProjectBasicSetting = lazy(() => import('@pages/ProjectBasicSetting'));
const ProjectApiSetting = lazy(() => import('@pages/ProjectApiSetting'));
const IndexBanner = lazy(() => import('@pages/IndexBanners'));
const ProjectTagManagement = lazy(() => import('@pages/TagManagement'));
const ProductSetting = lazy(() => import('@pages/ProductSetting'));
const DefaultPage = lazy(() => import('@pages/DefaultPage'));
const GiftingRecordsPage = lazy(() => import('@pages/GiftingRecords'));

export const publicConfig = [
  {
    name: names.LOGIN,
    path: paths.LOGIN,
    component: Login,
  },
  {
    name: 'root',
    path: paths.ROOT,
    component: DefaultPage,
  },
];

export const portalConfig = [
  {
    name: names.PROJECT_OVERVIEW,
    path: paths.PORTAL,
    component: ProjectOverview,
  },
];

export const privateConfig = [
  {
    name: names.DATA_REPORT,
    path: paths.DATA_REPORT,
    component: DataReport,
    breadcrumb: {
      show: false,
      parent: null,
    },
  },
  {
    name: names.USER_MANAGE,
    path: paths.USER_MANAGE,
    component: UserManage,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.TASK_USER_RECORDS,
    path: paths.TASK_USER_RECORDS,
    component: TaskUserRecords,
    breadcrumb: {
      show: true,
      parent: names.TASK_GROUPS,
    },
  },
  {
    name: names.TASK_GROUP_USER_RECORDS,
    path: paths.TASK_GROUP_USER_RECORDS,
    component: TaskGroupUserRecords,
    breadcrumb: {
      show: true,
      parent: names.TASK_GROUPS,
    },
  },
  {
    name: names.TASK_RECORDS,
    path: paths.TASK_RECORDS,
    component: TaskRecords,
    breadcrumb: {
      show: true,
      parent: names.TASK_GROUPS,
    },
  },
  {
    name: names.TASK_GROUP_RECORDS,
    path: paths.TASK_GROUP_RECORDS,
    component: TaskGroupRecords,
    breadcrumb: {
      show: true,
      parent: names.TASK_GROUPS,
    },
  },
  {
    name: names.TASK_GROUPS,
    path: paths.TASK_GROUPS,
    component: TaskGroups,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.PRICE_TO_POINT,
    path: paths.PRICE_TO_POINT,
    component: PriceToPoint,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.REDEEM_POINTS,
    path: paths.REDEEM_POINTS,
    component: RedeemPoints,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.AWARDS_USERS_RECORDS,
    path: paths.AWARDS_USERS_RECORDS,
    component: AwardUserRecords,
    breadcrumb: {
      show: true,
      parent: names.REDEEM_POINTS,
    },
  },
  {
    name: names.AWARDS_RECORDS,
    path: paths.AWARDS_RECORDS,
    component: AwardRecords,
    breadcrumb: {
      show: true,
      parent: names.REDEEM_POINTS,
    },
  },
  {
    name: names.LEVELE_QUITY,
    path: paths.LEVELE_QUITY,
    component: LevelEquity,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.GIFTING_RECORDS,
    path: paths.GIFTING_RECORDS,
    component: GiftingRecordsPage,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.PROJECT_INDEX_BANNERS,
    path: paths.INDEX_BANNERS,
    component: IndexBanner,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.PROJECT_BASIC_SETTING,
    path: paths.BASIC_SETTING,
    component: ProjectBasicSetting,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.COUPON_DISPATCH_SETTING,
    path: paths.COUPON_DISPATCH_SETTING,
    component: CouponDispatchPage,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.STORE_LIST,
    path: paths.STORE_LIST,
    component: StoreList,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.DEAL_RECORDS,
    path: paths.DEAL_RECORDS,
    component: DealRecords,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.PROJECT_COUPON_LIST,
    path: paths.COUPON_LIST,
    component: CouponPage,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.PROJECT_API_SETTING,
    path: paths.API_SETTING,
    component: ProjectApiSetting,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.PROJECT_TAG_MANAGEMENT,
    path: paths.TAG_MANAGEMENT,
    component: ProjectTagManagement,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
  {
    name: names.PRODUCT_SETTING,
    path: paths.PRODUCT_SETTING,
    component: ProductSetting,
    breadcrumb: {
      show: true,
      parent: null,
    },
  },
];

const fullConfig = [...publicConfig, ...privateConfig, ...portalConfig];

export default fullConfig;
