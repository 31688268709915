import { useQuery } from '@apollo/client';
import {
  GetProjectBasicQuery,
  GetProjectNamesQuery,
} from '@graphql/queries/project';
import useWindowSize from '@hooks/windowSize/useWindowSize';
import logoGoSkyLoyal from '@images/logo/GoSky-Loyal.svg';
import { BASIC_SETTING, CURRENT_PROJECT, PORTAL } from '@routes/routePaths';
import { get, merge } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  matchPath,
  useParams,
  useRouteMatch,
} from 'react-router';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import Accordion from '../Accordion';
import { defaultParams, SidebarLinks } from './getSidebarLinks';
import './index.css';

// render normal link if not nested
const DirectLinkItem = ({ item }) => {
  const match = useRouteMatch({
    path: CURRENT_PROJECT,
    strict: true,
    sensitive: true,
  });
  const { t } = useTranslation();

  return (
    <div className="sidebar-item">
      <NavLink
        to={generatePath(item.link, merge(defaultParams, match.params))}
        className="sidebar-link sidebar-item-link"
      >
        {item.sidebarIcon && <i className={`gsif ${item.sidebarIcon}`} />}
        {t(`sidebar.${item.sidebarLabel}`)}
      </NavLink>
    </div>
  );
};

// render accordion if nested
const NestedMenuItem = ({ item }) => {
  const location = useLocation();
  const active = useMemo(() => {
    const matched = item.links.findIndex((link) =>
      matchPath(location.pathname, {
        path: link.link,
        exact: true,
        strict: true,
      }),
    );
    if (matched !== -1) {
      return true;
    }
    return false;
  }, []);
  const [open, setOpen] = useState(active);
  const { t } = useTranslation();

  return (
    <Accordion
      open={open}
      onChange={() => setOpen(!open)}
      title={
        <div>
          <i className={`gsif ${item.sidebarIcon}`} />
          {t(`sidebar.${item.sidebarLabel}`)}
        </div>
      }
      className="sidebar-accordion"
      titleClass="cursor-pointer sidebar-item"
      chevronClass="sidebar-chevron"
    >
      <Menu links={item.links} />
    </Accordion>
  );
};

// decide what kind of list item will be render
const Menu = ({ links }) => {
  return links.map((listItem) => {
    if (listItem.hidden && listItem.hidden()) return false;

    return listItem.nested ? (
      <NestedMenuItem item={listItem} key={listItem.sidebarLabel} />
    ) : (
      <DirectLinkItem item={listItem} key={listItem.sidebarLabel} />
    );
  });
};

// actual sidebar content
const Sidebar = () => {
  const { projectId } = useParams();
  const { height: innerHeight } = useWindowSize();
  const location = useLocation();
  const history = useHistory();
  const { data } = useQuery(GetProjectNamesQuery);
  const projects = useMemo(() => get(data, 'projects.nodes', []), [data]);

  const { loading, data: queryResp } = useQuery(GetProjectBasicQuery, {
    variables: { projectId },
  });

  const logoElement = useMemo(
    () => (
      <Link to={PORTAL}>
        <img className="logo-sidebar" src={logoGoSkyLoyal} alt="GoSky Loyal" />
      </Link>
    ),
    [],
  );

  if (loading) return null;

  const workingMode = get(queryResp, 'project.workingMode', 'ONLINE');
  sessionStorage.setItem('working_mode', workingMode);

  const onProjectChange = (e) => {
    const { value } = e.target;
    history.push(generatePath(BASIC_SETTING, { projectId: value }));
    const isCurrentCustomSettingPage = !!matchPath(
      location.pathname,
      BASIC_SETTING,
    );
    if (isCurrentCustomSettingPage) history.go(0);
  };

  return (
    <div className="sidebar">
      {logoElement}
      <div className="select-sidebar-projects select">
        <select onChange={onProjectChange} value={projectId}>
          {projects.map((project) => {
            return (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            );
          })}
        </select>
      </div>
      <div
        className="scrollbar pr-4 pb-7 sidebar-menu"
        style={{ maxHeight: `calc(${innerHeight}px - 11.6875rem)` }}
      >
        <Menu links={SidebarLinks} />
      </div>
    </div>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  className: PropTypes.string,
  projectList: PropTypes.array,
  sidebarItems: PropTypes.array,
};
