import { find } from 'lodash-es';
import { matchPath } from 'react-router';
import routeConfig from '../../routes/config';

const findCurrentRoute = (location) => {
  return find(routeConfig, (item) => {
    const match = matchPath(location.pathname, {
      path: item.path,
      exact: true,
      strict: false,
    });
    return !!match;
  });
};

export default findCurrentRoute;
