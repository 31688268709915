import Navbar from '@components/Navbar';
import Sidebar from '@components/Sidebar';
import React from 'react';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  min-height: calc(100% - 4.25rem);

  & > div {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }

  & > div:first-child {
    margin-top: 1.5rem;
  }
`;

const PageContent = ({ children }) => {
  return (
    <>
      <Sidebar />
      <div className="flex-1 w-full h-full relative scrollbar">
        <Navbar displayLogo={false} />
        <ContentWrapper className="flex flex-col">{children}</ContentWrapper>
      </div>
    </>
  );
};

export default PageContent;
