import { useReducer } from 'react';
import initState from './initState';
import reducer from './reducer';

const useAuth = (userData) => {
  const [user, dispatch] = useReducer(
    reducer,
    initState,
    userData ? () => userData : undefined,
  );
  return [user, dispatch];
};

export default useAuth;
