import initState from './initState';
import * as types from './types';

const reducer = (state, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_USER_NAME:
      return {
        ...state,
        name: payload,
      };
    case types.SET_USER_LEVEL:
      return {
        ...state,
        level: payload,
      };
    case types.SET_USER_PORTRAIT_URL:
      return {
        ...state,
        portraitUrl: payload,
      };
    case types.RESET_USER:
      return initState;
    default:
      return state;
  }
};

export default reducer;
