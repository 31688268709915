import React, { useState, useEffect } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import Button from '../Button';

const ConfirmModal = ({
  id = 'confirm',
  title = '',
  titleClass = '',
  content = '',
  confirmBtnColor = 'red',
  confirmBtnText = '',
  isOpen = false,
  onConfirm = () => {},
  onCancel = () => {},
  cancelable = true,
}) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const onConfirmClick = () => {
    setLoading(true);
    onConfirm();
  };

  useEffect(() => {
    if (isOpen) setLoading(false);
  }, [isOpen]);

  return (
    <Modal id={id} isOpen={isOpen} persistent>
      <div className="confirm-modal">
        <p className="confirm-modal-title">
          <span className={titleClass}>{title}</span>
        </p>
        <p className="confirm-modal-content">{content}</p>
        <div className="confirm-modal-btn-wrapper">
          {cancelable && (
            <Button className="confirm-modal-btn" onClick={onCancel}>
              {t('common.cancel')}
            </Button>
          )}
          <Button
            className="confirm-modal-btn"
            color={confirmBtnColor}
            isLoading={isLoading}
            onClick={onConfirmClick}
          >
            {confirmBtnText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;

ConfirmModal.propTypes = {
  title: PropTypes.string,
  titleClass: PropTypes.string,
  content: PropTypes.string,
  confirmBtnColor: PropTypes.string,
  confirmBtnText: PropTypes.string,
  cancelable: PropTypes.bool,
};
