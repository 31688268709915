import React from 'react';

const ModalFormFooter = ({ children }) => {
  return (
    <div className="flex justify-between py-3.5 px-5.5 bg-white">
      {children}
    </div>
  );
};

export default ModalFormFooter;
