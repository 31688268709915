export const formatAdminInfo = (data) => {
  return {
    email: data.email,
    adminType: data.admin_type,
  };
};

export const formatAuthPayload = (data) => {
  return {
    email: data.username,
    password: data.password,
  };
};
