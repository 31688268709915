import { find, get, map, reverse } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link, useLocation, useParams } from 'react-router-dom';
import routeConfig from '../../routes/config';
import { prefix, suffix } from '../../utils/breadcrumb';
import findCurrentRoute from '../../utils/route/findCurrentRoute';
import './index.css';

const Breadcrumb = () => {
  const params = useParams();
  const location = useLocation();
  const currentRoute = findCurrentRoute(location);
  const { t } = useTranslation();

  const getBreadcrumbs = (route, result = []) => {
    const parentName = get(route, 'breadcrumb.parent');
    if (!parentName) {
      return result;
    }
    const parentRoute = find(routeConfig, { name: parentName });
    if (!parentRoute) return result;
    return getBreadcrumbs(parentRoute, [...result, parentRoute]);
  };

  const breadcrumbs = reverse(getBreadcrumbs(currentRoute, [currentRoute]));
  const currentBreadcrumb = breadcrumbs.pop();

  return (
    <div className="flex flex-wrap text-15 leading-5 items-center">
      {map(breadcrumbs, (breadcrumb) => {
        return (
          <Link
            key={breadcrumb.name}
            className="breadcrumb-item inline-flex text-blue no-underline hover:text-opacity-80"
            to={generatePath(breadcrumb.path, params)}
          >
            {`${prefix(breadcrumb.name)} `}
            {t(`breadcrumb.${breadcrumb.name}`)}
            {` ${suffix(breadcrumb.name)}`}
          </Link>
        );
      })}
      <span className="breadcrumb-item inline-flex text-dark-grey-300 cursor-default">
        {`${prefix(currentBreadcrumb.name)} `}
        {t(`breadcrumb.${currentBreadcrumb.name}`)}
        {` ${suffix(currentBreadcrumb.name)}`}
      </span>
    </div>
  );
};

export default Breadcrumb;
