import Loading from '@components/Loading';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Button = ({
  className,
  color = 'grey',
  block = false,
  isLoading = false,
  secondary,
  children,
  small,
  ...rest
}) => {
  const { disabled } = rest;
  const buttonClassName = classNames('btn', className, {
    [`btn-${color}`]: color && !secondary,
    [`btn-secondary`]: secondary,
    [`btn-${color}-secondary`]: color && secondary,
    [`btn-small`]: small,
    'btn-block': block,
  });
  return (
    <button
      type="button"
      className={buttonClassName}
      {...rest}
      disabled={disabled || isLoading}
    >
      {isLoading ? <Loading size="1.3125rem" color="white" /> : children}
    </button>
  );
};
Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['grey', 'blue', 'green', 'red', 'dark']),
  block: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  small: PropTypes.bool,
};

export default Button;
