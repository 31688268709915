import axios from 'axios';
import safeAwait from '../utils/api/safeAwait';
import {
  formatAdminInfo,
  formatAuthPayload,
} from '../utils/dataFormaters/auth';

export const getAdminInfo = async () => {
  const [resp, err] = await safeAwait(
    axios.get(`/api/console/v3/auth/admin_info`),
  );
  if (resp) return [formatAdminInfo(resp.data.data.admin_info), err];
  return [resp, err.response.data];
};

export const login = async (payload) => {
  const [resp, err] = await safeAwait(
    axios.post(`/api/console/v3/auth/login`, {
      admin: formatAuthPayload(payload),
    }),
  );
  if (resp) return [resp.data.data.token, err];
  return [resp, err.response.data];
};

export const logout = async () => {
  const [resp, err] = await safeAwait(
    axios.post(`/api/console/v3/auth/logout`),
  );
  if (resp) return [resp.data, err];
  return [resp, err.response.data];
};
