import PropTypes from 'prop-types';
import React from 'react';

const ModalFormHeader = ({ children, onCancel = () => {} }) => {
  return (
    <div className="flex justify-between items-start py-4.5 px-6">
      <p className="text-2xl font-medium text-dark-grey-700">{children}</p>
      <i
        className="gsif gsif-times cursor-pointer text-10 text-black opacity-20 hover:opacity-50"
        onClick={onCancel}
      ></i>
    </div>
  );
};

export default ModalFormHeader;

ModalFormHeader.propTypes = {
  onCancel: PropTypes.func,
};
