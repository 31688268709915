import './api';
import './assets/styles/common/_main.scss';
import './i18n/i18n';
import './index.css';

import { ApolloProvider } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom';
import { cssTransition, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import App from './App';
import AuthProvider from './context/Auth/AuthProvider';
import gqlClient from './services/graphql';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  if (window.location.protocol !== 'https:') {
    window.location.replace(
      `https:${window.location.href.substring(
        window.location.protocol.length,
      )}`,
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={gqlClient}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ApolloProvider>
    <ToastContainer
      autoClose={3000}
      closeButton={false}
      transition={cssTransition({
        enter: 'animate__fadeIn',
        exit: 'animate__fadeOut',
        appendPosition: false,
        collapse: true,
        collapseDuration: 300,
      })}
      hideProgressBar={true}
    />
    <ReactTooltip id="tooltip-date" className="tooltip-date" effect="solid" />
    <ReactTooltip id="tooltip-hint" className="tooltip-hint" effect="solid" />
    <ReactTooltip id="tooltip-btn" className="tooltip-btn" effect="solid" />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
