import { gql } from '@apollo/client';

import { OrganizationFragment } from '../fragments/organization';
import {
  CustomSettingFragment,
  PriceToPointFragment,
  ProjectProductTagsFragment,
  ProjectApiFragment,
  ProjectBasicInfoFragment,
  ProjectLevelsFragment,
  ProjectNameFragment,
  ProjectStoreTagsFragment,
  ProjectUserTagsFragment,
} from '../fragments/project';

export const GetProjectsQuery = gql`
  query GetProjectsQuery {
    projects {
      nodes {
        ...ProjectBasicInfoFragment
        organization {
          ...OrganizationFragment
        }
      }
    }
  }
  ${ProjectBasicInfoFragment}
  ${OrganizationFragment}
`;

export const GetProjectNamesQuery = gql`
  query GetProjectNamesQuery {
    projects {
      nodes {
        ...ProjectNameFragment
      }
    }
  }
  ${ProjectNameFragment}
`;

export const GetCustomSettingQuery = gql`
  query Project($projectId: ID!) {
    project(id: $projectId) {
      ...CustomSettingFragment
    }
  }
  ${CustomSettingFragment}
`;

export const GetProjectLevelsQuery = gql`
  query GetProjectLevelsQuery($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectLevelsFragment
    }
  }
  ${ProjectLevelsFragment}
`;

export const GetProjectApiQuery = gql`
  query GetProjectApiQuery($projectId: ID!) {
    project(id: $projectId) {
      ...ProjectApiFragment
    }
  }
  ${ProjectApiFragment}
`;

export const GetProjectTagsQuery = (type) => gql`
  query GetProjectTagsQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      ${!type || type === 'store' ? '...ProjectStoreTagsFragment' : ''}
      ${!type || type === 'user' ? '...ProjectUserTagsFragment' : ''}
      ${!type || type === 'product' ? '...ProjectProductTagsFragment' : ''}
    }
  }
  ${!type || type === 'store' ? ProjectStoreTagsFragment : ''}
  ${!type || type === 'user' ? ProjectUserTagsFragment : ''}
  ${!type || type === 'product' ? ProjectProductTagsFragment : ''}
`;

export const GetPriceToPointQuery = gql`
  query GetPriceToPointQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      priceToPointSetting {
        ...PriceToPointFragment
      }
    }
  }
  ${PriceToPointFragment}
`;

export const GetPointVersionSetting = gql`
  query GetPointVersionSetting($id: ID!) {
    project(id: $id) {
      id
      pointVersionSetting
    }
  }
`;

export const GetProjectBasicQuery = gql`
  query GetProjectBasicQuery($projectId: ID!) {
    project(id: $projectId) {
      id
      workingMode
      smsQuotaNum
      posVersion
      preferences {
        needOtp
      }
    }
  }
`;
