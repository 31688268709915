import { RECORDS_TYPE_POS } from '@constants/smrsSetting';
import * as paths from '@routes/routePaths';
import { isOnline } from '@utils/storage';

export const defaultParams = {
  id: ' ',
  typeId: ' ',
  taskId: ' ',
  taskGroupId: ' ',
  awardId: ' ',
  recordsType: RECORDS_TYPE_POS,
  a: ' ',
  b: ' ',
};

export const SidebarLinks = [
  {
    sidebarLabel: 'dataReport',
    sidebarIcon: 'gsif-dashboard',
    link: paths.DATA_REPORT,
    nested: false,
  },
  {
    sidebarLabel: 'userManage',
    sidebarIcon: 'gsif-people',
    link: paths.USER_MANAGE,
    nested: false,
  },
  {
    sidebarLabel: 'levelAndPoint',
    sidebarIcon: 'gsif-badge',
    nested: true,
    links: [
      {
        sidebarLabel: 'taskGroups',
        link: paths.TASK_GROUPS,
        nested: false,
        links: [
          {
            link: paths.TASK_USER_RECORDS,
          },
          {
            link: paths.TASK_GROUP_USER_RECORDS,
          },
          {
            link: paths.TASK_RECORDS,
          },
          {
            link: paths.TASK_GROUP_RECORDS,
          },
        ],
      },
      {
        sidebarLabel: 'priceToPoint',
        link: paths.PRICE_TO_POINT,
        hidden: () => isOnline(),
      },
      {
        sidebarLabel: 'redeemPoints',
        link: paths.REDEEM_POINTS,
        nested: false,
        links: [
          {
            link: paths.AWARDS_USERS_RECORDS,
          },
          {
            link: paths.AWARDS_RECORDS,
          },
        ],
      },
      {
        sidebarLabel: 'levelEquity',
        link: paths.LEVELE_QUITY,
      },
      {
        sidebarLabel: 'giftingRecords',
        link: paths.GIFTING_RECORDS,
      },
    ],
  },
  {
    sidebarLabel: 'store',
    sidebarIcon: 'gsif-store',
    hidden: () => isOnline(),
    nested: true,
    links: [
      {
        sidebarLabel: 'storeList',
        link: paths.STORE_LIST,
      },
      {
        sidebarLabel: 'dealRecords',
        link: paths.DEAL_RECORDS,
      },
      {
        sidebarLabel: 'coupons',
        link: paths.COUPON_LIST,
      },
      {
        sidebarLabel: 'couponDispatch',
        link: paths.COUPON_DISPATCH_SETTING,
      },
      {
        sidebarLabel: 'productSetting',
        link: paths.PRODUCT_SETTING,
      },
    ],
  },
  {
    sidebarLabel: 'setting',
    sidebarIcon: 'gsif-cog',
    nested: true,
    links: [
      {
        sidebarLabel: 'basicSetting',
        link: paths.BASIC_SETTING,
      },
      {
        sidebarLabel: 'indexBanners',
        link: paths.INDEX_BANNERS,
      },
      {
        sidebarLabel: 'apiSetting',
        link: paths.API_SETTING,
      },
      {
        sidebarLabel: 'tagManagement',
        link: paths.TAG_MANAGEMENT,
      },
    ],
  },
];
