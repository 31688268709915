import classNames from 'classnames';
import { get, map } from 'lodash-es';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const ActionList = forwardRef(
  (
    {
      actions = [],
      className = '',
      align = 'right',
      children,
      once = true,
      onClose,
      btnClass = '',
      persist,
    },
    ref,
  ) => {
    const [isOpen, setOpen] = useState(persist ? true : false);
    const dropdownClass = classNames('dropdown', className, { open: isOpen });
    const listRef = useRef();
    const listWidth = useRef();
    const parentRef = useRef();
    const dropdownButtonRef = useRef(null);

    const onButtonClick = () => {
      if (ref) {
        ref.current.focus();
      } else {
        dropdownButtonRef.current.focus();
      }
      setOpen(true);
    };

    useEffect(() => {
      listWidth.current = listRef.current.offsetWidth;
    }, [actions]);

    const catchBlur = (event) => {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        setOpen(persist ? true : false);
        onClose && onClose();
      } else {
        dropdownButtonRef.current.focus();
      }
    };

    const pinDropdown = () => {
      const buttonCurrent =
        get(ref, 'current') || get(dropdownButtonRef, 'current');
      const buttonRect = buttonCurrent
        ? buttonCurrent.getBoundingClientRect()
        : null;

      return buttonRect
        ? `top: ${buttonRect.top + buttonRect.height}px; left: ${
            buttonRect.left +
            (align === 'left' ? 0 : buttonRect.width - listWidth.current)
          }px`
        : null;
    };
    const onlyOneClick = once ? catchBlur : null;

    return (
      <span
        className={dropdownClass}
        ref={parentRef}
        tabIndex={1}
        onBlur={once ? null : catchBlur}
      >
        {children ? (
          <button
            ref={ref || dropdownButtonRef}
            className={`btn-dropdown ${btnClass}`}
            onClick={onButtonClick}
            onBlur={onlyOneClick}
          >
            {children}
          </button>
        ) : (
          <button
            ref={ref || dropdownButtonRef}
            className="btn-dropdown btn-icon"
            onClick={onButtonClick}
            onBlur={onlyOneClick}
          >
            <i className="gsif gsif-ellipsis-h" />
          </button>
        )}
        <Contents
          ref={listRef}
          pinDropdown={pinDropdown}
          actions={actions}
          isOpen={isOpen}
          parentRef={parentRef}
        />
      </span>
    );
  },
);

const Contents = forwardRef(({ pinDropdown, actions, isOpen }, ref) => {
  const composeButton = useCallback(
    (prependIcon, text) => {
      return prependIcon ? (
        <>
          <i className={`gsif ${prependIcon}`} />
          <span className="ml-2">{text}</span>
        </>
      ) : (
        text
      );
    },
    [actions],
  );

  useEffect(() => {
    if (isOpen) {
      applyStyle();
      window.addEventListener('wheel', applyStyle);
      window.addEventListener('scroll', applyStyle);
    }
    return () => {
      window.removeEventListener('wheel', applyStyle);
      window.removeEventListener('scroll', applyStyle);
    };
  }, [isOpen]);

  const applyStyle = () => {
    if (ref.current) {
      ref.current.style = pinDropdown();
    }
  };

  return (
    <>
      <span ref={ref} className="list-dropdown shadow-light">
        {map(
          actions,
          (
            {
              className: itemClassName,
              text,
              onMouseDown,
              disabled,
              component,
              prependIcon,
            },
            index,
          ) =>
            component ? (
              <div key={index} className="text-left btn-dropdown-item">
                {component()}
              </div>
            ) : (
              <button
                key={`${text}-${index}`}
                className={`btn-dropdown-item text-left ${itemClassName}`}
                onMouseDown={onMouseDown}
                disabled={disabled}
              >
                {composeButton(prependIcon, text)}
              </button>
            ),
        )}
      </span>
      {isOpen && (
        <span
          className="block absolute w-px left-0 top-0"
          style={{ height: (ref?.current?.offsetHeight || 0) + 30 }}
        />
      )}
    </>
  );
});

export default ActionList;

ActionList.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      text: PropTypes.string,
      prependIcon: PropTypes.string,
      onMouseDown: PropTypes.func,
      disabled: PropTypes.bool,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    }),
  ),
  className: PropTypes.string,
  children: PropTypes.node,
  align: PropTypes.oneOf(['left', 'right']),
  once: PropTypes.bool,
  persist: PropTypes.bool,
};
