import { getAdminInfo } from '@api/auth';
import LoadingPageContent from '@components/LoadingPageContent';
import Navbar from '@components/Navbar';
import PageContent from '@containers/PageContent';
import AuthContext from '@context/Auth/AuthContext';
import { SET_USER_LEVEL, SET_USER_NAME } from '@context/Auth/types';
import useWindowSize from '@hooks/windowSize/useWindowSize';
import Breadcrumb from '@pages/Breadcrumb';
import { get, isEmpty } from 'lodash-es';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { portalConfig, privateConfig, publicConfig } from './config';
import PrivateRoute from './PrivateRoute';

const RouteGuard = () => {
  const { height: innerHeight } = useWindowSize();
  const { user, dispatch: dispatchUser } = useContext(AuthContext);
  const [initCompleted, setInitCompleted] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (isEmpty(user.level)) {
      const token = localStorage.getItem('smrs_t');

      const getAdmin = async () => {
        const [adminInfoResp, adminInfoError] = await getAdminInfo();

        if (adminInfoError) {
          localStorage.removeItem('smrs_t');
        }

        if (adminInfoResp) {
          dispatchUser({
            type: SET_USER_NAME,
            payload: adminInfoResp.email,
          });
          dispatchUser({
            type: SET_USER_LEVEL,
            payload: adminInfoResp.adminType,
          });
        }

        setInitCompleted(true);
      };
      if (isMounted && token) getAdmin();
      if (isMounted && !token) setInitCompleted(true);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  if (!initCompleted) return null;

  return (
    <div
      className="flex"
      style={{ maxHeight: `${innerHeight}px`, height: `${innerHeight}px` }}
    >
      <Switch>
        {/* PRIVATE ROUTE */}
        <Route path="/project/:projectId">
          <PageContent>
            <Switch>
              {privateConfig.map((route) => (
                <Route path={route.path} key={route.name}>
                  <PrivateRoute>
                    <Suspense fallback={<LoadingPageContent />}>
                      {!!get(route, 'breadcrumb.show') && <Breadcrumb />}
                      <route.component />
                    </Suspense>
                  </PrivateRoute>
                </Route>
              ))}
            </Switch>
          </PageContent>
        </Route>

        {/* PORTAL ROUTE */}
        <Route path="/portal/:path?">
          <Switch>
            {portalConfig.map((route) => (
              <Route path={route.path} key={route.name} exact>
                <PrivateRoute key={route.name}>
                  <div className="flex-1">
                    <Navbar />
                    <Suspense fallback={<LoadingPageContent />}>
                      <route.component />
                    </Suspense>
                  </div>
                </PrivateRoute>
              </Route>
            ))}
          </Switch>
        </Route>

        {/* PUBLIC ROUTE */}
        <Route path="/">
          <Switch>
            {publicConfig.map((route) => (
              <Route path={route.path} key={route.name}>
                <div className="flex-1">
                  <Suspense fallback={<LoadingPageContent />}>
                    <route.component />
                  </Suspense>
                </div>
              </Route>
            ))}
          </Switch>
        </Route>
      </Switch>
    </div>
  );
};

export default RouteGuard;
