import React from 'react';
import AuthContext from './AuthContext';
import useAuth from './useAuth';

const AuthProvider = ({ children }) => {
  const [user, dispatch] = useAuth();
  return (
    <AuthContext.Provider value={{ user, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
