import Button from '@components/Button';
import Modal from '@components/Modal';
import Content from '@components/Modal/Form/Content';
import Footer from '@components/Modal/Form/Footer';
import Header from '@components/Modal/Form/Header';
import { SMS_LOW_QUOTA_NUM, WIRED_NOTIFICATION } from '@constants/smrsSetting';
import Tutorial from '@images/info/OTP-tutorial.png';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const OTPModal = ({ open = false, close = () => {}, smsQuotaNum = 0 }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={open} onRequestClose={close} style={{ width: '37.1875rem' }}>
      <Header onCancel={close}>
        <span className="block">{t('otpModal.title')}</span>
        <span className="block text-15 text-dark-grey-500 mt-12 leading-5">
          {t('otpModal.smsBalance')}
          <span
            className={
              smsQuotaNum <= SMS_LOW_QUOTA_NUM ? `text-red` : `text-blue`
            }
          >
            {smsQuotaNum.toLocaleString()}
          </span>
        </span>
        <span className="block text-15 text-dark-grey-500 leading-5">
          {t('otpModal.description')}
        </span>
      </Header>
      <img src={Tutorial} alt="otp-tutorial" />
      <Content className="text-center pt-5">
        <a
          className="text-blue-300 underline"
          href={WIRED_NOTIFICATION}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('otpModal.wiredLink')}
        </a>
      </Content>
      <Footer className="flex">
        <Button onClick={close}>{t('common.cancel')}</Button>
        <Button color="blue" onClick={close}>
          {t('common.finish')}
        </Button>
      </Footer>
    </Modal>
  );
};

export default OTPModal;

OTPModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  smsQuotaNum: PropTypes.number,
};
